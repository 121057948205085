import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import { usePageContext, IPage } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    section,
    sectionCases,
    title,
    subtitle,
    slider,
    navigation,
    navButton,
    container,
    accent,
    button,
    slideCard,
} from './section-ecommerce-cases.module.scss';
import ArrowLeft from '../../assets/images/svg/arrow-left.svg';
import ArrowRight from '../../assets/images/svg/arrow-right.svg';
import KidarooLogo from '../../assets/images/svg/logo-kidaroo.svg';
import KlinikaSmykaLogo from '../../assets/images/svg/logo-klinika-smyka.svg';
import KultoweAlkoholeLogo from '../../assets/images/svg/logo-klutowe-alkohole.svg';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IProject } from '../../models/project';
import { useT } from '../../hooks/use-translation';
import { getNodes } from '../../utils/get-nodes';

import Section from '../hoc/section';
import CaseStudyCard, { ICaseStudyCard } from '../molecules/case-study-card';

interface ISectionEcommerceCasesProps {
    className?: string;
    id?: string;
}

interface ISectionEcommerceCasesQueryResult {
    imageKidaroo: ImageDataLike;
    imageKlinikaSmyka: ImageDataLike;
    imageKultoweAlkohole: ImageDataLike;
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname'>>;
    allProject: IQueryAllResult<Pick<IProject, 'client' | 'pathname' | 'locale'>>;
}

const SectionEcommerceCases: React.FC<ISectionEcommerceCasesProps> = ({ className = '', id }) => {
    const { locale } = usePageContext();
    const {
        imageKlinikaSmyka,
        imageKultoweAlkohole,
        imageKidaroo,
        allPage,
        allProject,
    }: ISectionEcommerceCasesQueryResult = useStaticQuery(query);
    const { t } = useT();
    const pathname = getNodes(allPage).find((page) => page.locale === locale)?.pathname;
    const projects = getNodes(allProject);
    const kidarooPathname = projects.find(
        (project) => project.locale === locale && project.client === 'Kidaroo'
    )?.pathname;
    const klinikaSmykaPathname = projects.find(
        (project) => project.locale === locale && project.client === 'Klinika Smyka'
    )?.pathname;
    const kultoweAlkoholePathname = projects.find(
        (project) => project.locale === locale && project.client === 'Kultowe Alkohole'
    )?.pathname;

    const caseCards: ICaseStudyCard[] = [
        {
            logo: KidarooLogo,
            image: imageKidaroo,
            tags: t('section.cases.card.tags.1').split(','),
            specs: [
                {
                    value: t('section.cases.card.spec.1.value.1'),
                    label: t('section.cases.card.spec.1.label.1'),
                },
                {
                    value: t('section.cases.card.spec.1.value.2'),
                    label: t('section.cases.card.spec.1.label.2'),
                },
            ],
            description: t('section.cases.card.description.1'),
            pathname: kidarooPathname,
        },
        {
            logo: KlinikaSmykaLogo,
            image: imageKlinikaSmyka,
            tags: t('section.cases.card.tags.2').split(','),
            specs: [
                {
                    value: t('section.cases.card.spec.2.value.1'),
                    label: t('section.cases.card.spec.2.label.1'),
                },
                {
                    value: t('section.cases.card.spec.2.value.2'),
                    label: t('section.cases.card.spec.2.label.2'),
                },
            ],
            description: t('section.cases.card.description.2'),
            pathname: klinikaSmykaPathname,
        },
        {
            logo: KultoweAlkoholeLogo,
            image: imageKultoweAlkohole,
            tags: t('section.cases.card.tags.3').split(','),
            specs: [
                {
                    value: t('section.cases.card.spec.3.value.1'),
                    label: t('section.cases.card.spec.3.label.1'),
                },
                {
                    value: t('section.cases.card.spec.3.value.2'),
                    label: t('section.cases.card.spec.3.label.2'),
                },
            ],
            description: t('section.cases.card.description.3'),
            pathname: kultoweAlkoholePathname,
        },
    ];

    return (
        <Section
            id={id}
            className={`${className} ${section} ${sectionCases}`}
            theme="light"
            circleDisplay="none"
            contentDisplay="left"
            titleProps={{
                children: (
                    <>
                        {t('section.cases.title.1')}{' '}
                        <span className={accent}>
                            {t('section.cases.title.2')}
                            <span>.</span>
                        </span>
                    </>
                ),
                subtitle: t('section.cases.subtitle'),
                subtitleColor: 'pink',
                className: title,
                subtitleClass: subtitle,
                weight: 'bold',
            }}
            buttonProps={{
                children: t('section.cases.button'),
                className: button,
                color: 'yellow',
                kind: 'normalDark',
                to: pathname || '',
                as: 'link',
            }}
        >
            <div className={container}>
                <div className={navigation}>
                    <button className={`${navButton} cases-slider-nav-button-left`}>
                        <ArrowLeft />
                    </button>
                    <button className={`${navButton} cases-slider-nav-button-right`}>
                        <ArrowRight />
                    </button>
                </div>
                <Swiper
                    className={slider}
                    modules={[Navigation]}
                    loop={true}
                    slidesPerView="auto"
                    breakpoints={{ 0: { spaceBetween: 20 }, 600: { spaceBetween: 30 } }}
                    navigation={{
                        prevEl: '.cases-slider-nav-button-left',
                        nextEl: '.cases-slider-nav-button-right',
                    }}
                >
                    {caseCards.map((card, index) => {
                        return (
                            <SwiperSlide key={`cards-${index}`}>
                                <CaseStudyCard className={slideCard} card={card} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </Section>
    );
};

const query = graphql`
    query {
        imageKidaroo: file(relativePath: { eq: "case-study-kidaroo.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 90)
            }
        }
        imageKlinikaSmyka: file(relativePath: { eq: "case-study-klinika-smyka.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 90)
            }
        }
        imageKultoweAlkohole: file(relativePath: { eq: "case-study-kultowe-alkohole.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 90)
            }
        }
        allPage(filter: { type: { eq: "portfolio" } }) {
            edges {
                node {
                    locale
                    pathname
                }
            }
        }
        allProject(filter: { client: { in: ["Kultowe Alkohole", "Klinika Smyka", "Kidaroo"] } }) {
            edges {
                node {
                    client
                    locale
                    pathname
                }
            }
        }
    }
`;

export default SectionEcommerceCases;
