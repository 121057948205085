import React from 'react';
import { ImageDataLike, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import {
    container,
    content,
    contentTop,
    logoBox,
    imgContent,
    imgBox,
    img,
    header,
    text,
    tagList,
    tagItem,
    specList,
    specValue,
    specLabel,
} from './case-study-card.module.scss';
import Markdown from '../hoc/markdown';

export interface ICaseStudyCard {
    logo: React.SVGFactory;
    description: string;
    specs: { label: string; value: string }[];
    tags: string[];
    image: ImageDataLike;
    pathname?: string;
}

interface ICaseStudyCardProps {
    className?: string;
    card: ICaseStudyCard;
}

const CaseStudyCard: React.FC<ICaseStudyCardProps> = ({ card, className = '' }) => {
    const Icon = card.logo;
    const image = getImage(card.image);

    return (
        <div className={`${container} ${className}`}>
            <div className={content}>
                <div className={contentTop}>
                    <div className={header}>
                        <div className={logoBox}>{Icon && <Icon />}</div>
                    </div>
                    <div className={specList}>
                        {card.specs.map((spec, index) => {
                            return (
                                <div>
                                    <p className={specValue}>{spec.value}</p>
                                    <p className={specLabel}>{spec.label}</p>
                                </div>
                            );
                        })}
                    </div>
                    <Markdown className={text}>{card.description}</Markdown>
                </div>
                <div className={tagList}>
                    {card.tags.map((tag, index) => {
                        return (
                            <p className={tagItem} key={`tag-${index}`}>
                                {tag}
                            </p>
                        );
                    })}
                </div>
            </div>
            <div className={imgContent}>
                <Link to={card.pathname || ''} className={imgBox}>
                    {image && (
                        <GatsbyImage
                            className={img}
                            alt=""
                            image={image}
                            objectFit="cover"
                            objectPosition="top left"
                        />
                    )}
                </Link>
            </div>
        </div>
    );
};

export default CaseStudyCard;
